import React from "react";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import { Typography } from "@ryerson/frontend.typography";
import { Link } from "@ryerson/frontend.navigation";
import { css } from "@emotion/react";
import { Button } from "@ryerson/frontend.button";
import styled from "@emotion/styled";
import { Icon } from "@ryerson/frontend.assets";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useTheme } from "@ryerson/frontend.theme";
import { Media } from "@ryerson/frontend.layout";
import { Modal } from "@ryerson/frontend.modal";
import { MakeHighlights } from "@components/Glossary/Page/Page";
import { Video } from "@ryerson/frontend.media";

export const companyToCompany = (company: any) => {
	const contentfulCompany: any = {
		ryerson: "Ryerson",
		southernToolSteel: "Southern Tool Steel",
	};
	return contentfulCompany[company] ? contentfulCompany[company] : contentfulCompany.ryerson;
};

const ImageContainer = styled.div`
	display: block;
	width: auto;
	height: auto;
	position: relative;
`;

const IconContainer = styled.div`
	display: block;
	position: absolute;
	top: 7px;
	text-align: center;
	line-height: 28px;
	border-radius: 24px;
	height: 24px;
	width: 24px;
	right: 7px;
`;

const ImageWrapper = (props: any) => {
	const { theme } = useTheme();
	const [modalOpen, setModalOpen] = React.useState<boolean>(false);
	return (
		<>
			<ImageContainer>
				<IconContainer
					css={css`
						background-color: rgba(255, 255, 255, 0.8);
					`}
				>
					<Icon
						icon="scan"
						size="xs"
						color={theme.colors.primary.header}
						circled={false}
						selected={false}
						onClick={() => {
							setModalOpen(true);
						}}
					/>
				</IconContainer>
				{props.children ?? ""}
				<Modal
					isOpen={modalOpen}
					closable={true}
					onClose={() => {
						setModalOpen(false);
					}}
				>
					{props.children ?? ""}
				</Modal>
			</ImageContainer>
		</>
	);
};

export const renderRichText = (
	rawData: any,
	type: any,
	references?: any | null,
	searchTerm?: string | null
) => {
	const Bold = ({ children }: any) => <Typography weight="bold">{children}</Typography>;
	const Italics = ({ children }: any) => <Typography italic>{children}</Typography>;
	const Underline = ({ children }: any) => <Typography underline>{children}</Typography>;

	const Text = ({ children }: any) => {
		let newKids: any[] = [];
		const uniqueArrayItems = (value: any, index: any, self: any) => {
			return self.indexOf(value) === index;
		};
		children.forEach((child: any, index: number) => {
			if (searchTerm && searchTerm.length > 2) {
				if (
					typeof child === "string" &&
					child.toLowerCase().includes(searchTerm.toLowerCase())
				) {
					let re = new RegExp(searchTerm, "gi");
					let match = child.match(re);
					if (match && match.length > 0) {
						match = match.filter(uniqueArrayItems);
						let newChild = child;
						for (let i = 0; i < match.length; i++) {
							newChild = newChild.replaceAll(match[i], "|" + match[i] + "|");
						}
						newKids.push(
							<React.Fragment key={index}>{MakeHighlights(newChild)}</React.Fragment>
						);
					} else {
						newKids.push(<React.Fragment key={index}>{child}</React.Fragment>);
					}
				} else {
					newKids.push(<React.Fragment key={index}>{child}</React.Fragment>);
				}
			} else {
				newKids.push(<React.Fragment key={index}>{child}</React.Fragment>);
			}
		});
		return (
			<Typography
				variant="p"
				css={css`
					// margin-top: 0px;
					margin-bottom: 21px;
				`}
				type={type ?? "primary"}
			>
				{newKids}
			</Typography>
		);
	};

	const options = {
		renderMark: {
			[MARKS.BOLD]: (text: any) => <Bold>{text}</Bold>,
			[MARKS.ITALIC]: (text: any) => <Italics>{text}</Italics>,
			[MARKS.UNDERLINE]: (text: any) => <Underline>{text}</Underline>,
		},
		renderNode: {
			[INLINES.HYPERLINK]: (node: any, children: any) => (
				<Link to={node.data.uri}>{children}</Link>
			),
			[BLOCKS.PARAGRAPH]: (node: any, children: any) => <Text>{children}</Text>,

			[BLOCKS.EMBEDDED_ASSET]: (node: any, children: any) => {
				if (references) {
					let asset: any;
					references.forEach((ref: any, index: any) => {
						if (ref.contentful_id === node.data.target.sys.id) {
							asset = ref;
						}
					});
					if (asset) {
						return (
							<>
								<Media greaterThanOrEqual="lg">
									<img src={asset.file.url} alt={asset.title} />
								</Media>
								<Media lessThan="lg">
									<ImageWrapper>
										<img src={asset.file.url} alt={asset.title} />
									</ImageWrapper>
								</Media>
							</>
						);
					} else {
						return <></>;
					}
				} else {
					return <></>;
				}
			},
		},
	};
	return documentToReactComponents(JSON.parse(rawData), options);
};

type ImageWithCaption = {
	image: string;
	title?: string;
	caption: string;
	type?: "primary" | "secondary" | "tertiary";
};

const ImageCaptionContainer = styled.div`
	display: block;
	width: calc(100% - 80px);
	height: auto;
	margin-top: 56px;
	margin-bottom: 56px;
	margin-left: 40px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media (max-width: ${theme.breakpoints.lg}) {
				width: calc(100% - 40px);
				margin-left: 20px;
				margin-top: 42px;
				margin-bottom: 42px;
			}
		`;
	}}
`;

const CaptionedImageContainer = styled.div`
	display: inline-block;
	width: calc(60% - 40px);
	vertical-align: bottom;
	height: auto;
	img {
		width: 100%;
		height: auto;
		display: block;
	}
	${(props: any) => {
		const { theme } = props;
		return css`
			@media (max-width: ${theme.breakpoints.lg}) {
				display: block;
				width: 100%;
				margin-bottom: 10px;
			}
		`;
	}}
`;
const CaptionContainer = styled.div`
	display: inline-block;
	width: 40%;
	margin-left: 40px;
	vertical-align: bottom;
	height: auto;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media (max-width: ${theme.breakpoints.lg}) {
				display: block;
				width: 100%;
				margin-left: 0px;
			}
		`;
	}}
`;

const CaptionedImage: React.FC<ImageWithCaption> = ({
	image,
	title,
	caption,
	type = "primary",
}) => {
	const { theme } = useTheme();
	return (
		<ImageCaptionContainer theme={theme}>
			<CaptionedImageContainer theme={theme}>
				<Media greaterThanOrEqual="lg">
					<img src={image} alt={title ?? "Image"} />
				</Media>
				<Media lessThan="lg">
					<ImageWrapper>
						<img src={image} alt={title ?? "Image"} />
					</ImageWrapper>
				</Media>
			</CaptionedImageContainer>
			<CaptionContainer theme={theme}>
				<Typography variant="div" type={type} size="sm">
					{caption}
				</Typography>
			</CaptionContainer>
		</ImageCaptionContainer>
	);
};

const VideoContainer = styled.div`
	width: 100%;
	display: block;
	height: 360px;
`;

const ThingLinkContainer = styled.div`
	display: block;
	width: 100%;
	height: auto;
`;

type Infogram = {
	infogramId: string;
	title: string;
};

const Infogrammer = (props: Infogram) => {
	React.useEffect(() => {
		let t: any = "InfogramEmbeds";
		let d = document.getElementsByTagName("script")[0];
		if ((window as any)[t] && (window as any)[t].initialized)
			(window as any)[t].process && (window as any)[t].process();
		else if (!document.getElementById("infogram-async")) {
			var o = document.createElement("script");
			o.async = true;
			o.id = "infogram-async";
			o.src = "https://e.infogram.com/js/dist/embed-loader-min.js";
			if (d.parentNode) d.parentNode.insertBefore(o, d);
		}
	}, []);
	return (
		<ThingLinkContainer>
			<div
				className="infogram-embed"
				data-id={props.infogramId}
				data-type="interactive"
				data-title={props.title}
			></div>
		</ThingLinkContainer>
	);
};

type ThingLink = {
	thinglinkId: string;
	originalHeight: number;
	originalWidth: number;
};

const ThingLinker = (props: ThingLink) => {
	const isWindow = typeof window !== "undefined";
	const thinglinkId = props.thinglinkId;
	const aspect = props.originalHeight / props.originalWidth;
	const [thingH, setThingH] = React.useState<number>(props.originalHeight);
	const [thingW, setThingW] = React.useState<number>(props.originalWidth);
	React.useEffect(() => {
		if (isWindow) {
			if (document && document.getElementById(thinglinkId) !== null) {
				let width = document.getElementById(thinglinkId)?.offsetWidth;
				if (width) {
					setThingW(width);
					let height = width * aspect;
					setThingH(height);
				}
			}
			window.addEventListener("resize", () => {
				if (document && document.getElementById(thinglinkId) !== null) {
					let width = document.getElementById(thinglinkId)?.offsetWidth;
					if (width) {
						setThingW(width);
						let height = width * aspect;
						setThingH(height);
					}
				}
			});
		}
	}, []);
	return (
		<ThingLinkContainer id={props.thinglinkId}>
			<iframe
				width={thingW}
				height={thingH}
				data-original-width={props.originalWidth}
				data-original-height={props.originalHeight}
				css={css`
					border: none;
				`}
				scrolling="no"
				src={"https://www.thinglink.com/card/" + props.thinglinkId}
			></iframe>
		</ThingLinkContainer>
	);
};

export const renderBlogRichText = (rawData: any, type: any, references?: any | null) => {
	const { theme } = useTheme();
	const Bold = ({ children }: any) => <Typography weight="bold">{children}</Typography>;
	const Italics = ({ children }: any) => <Typography italic>{children}</Typography>;
	const Underline = ({ children }: any) => <Typography underline>{children}</Typography>;
	const options = {
		renderMark: {
			[MARKS.BOLD]: (text: any) => <Bold>{text}</Bold>,
			[MARKS.ITALIC]: (text: any) => <Italics>{text}</Italics>,
			[MARKS.UNDERLINE]: (text: any) => <Underline>{text}</Underline>,
		},
		renderNode: {
			[INLINES.HYPERLINK]: (node: any, children: any) => {
				let ele;
				if (typeof children[0] === "string") {
					let content = children[0];
					if (content.includes("[") && content.includes("]")) {
						let match = content.match("\\[.*]");
						if (match && match.length > 0) {
							let placeholder = match[0];
							content = content.replace(placeholder, "");
							switch (placeholder) {
								case "[pdf]":
									ele = (
										<span
											css={css`
												display: block;
												margin: 36px 0px 64px;
												@media (max-width: ${theme.breakpoints.lg}) {
													margin: 24px 0px 44px;
												}
											`}
										>
											<Button
												shape="rounded"
												size="xs"
												leftIcon="file-pdf"
												paddingType="relaxed"
												type="secondary"
												foreground="light"
												label={content}
												onClick={() => {}}
											/>
										</span>
									);
									break;
								default:
									ele = children[0];
									break;
							}
						} else {
							ele = children[0];
						}
					} else {
						ele = children[0];
					}
				} else {
					ele = children[0];
				}
				return <Link to={node.data.uri}>{ele}</Link>;
			},
			[INLINES.EMBEDDED_ENTRY]: (node: any, children: any) => {
				if (references) {
					let asset: any;
					references.forEach((ref: any, index: any) => {
						if (ref.contentful_id === node.data.target.sys.id) {
							asset = ref;
						}
					});
					if (asset) {
						if (asset.videoId) {
							return (
								<>
									<Media greaterThanOrEqual="lg">
										<VideoContainer>
											<Video
												videoId={asset.videoId}
												buttonOrientation="right"
												imageUrl={asset.thumbnail.file.url}
												text={asset.title}
											/>
										</VideoContainer>
									</Media>
									<Media lessThan="lg">
										<VideoContainer
											css={css`
												height: 240px;
												margin-top: 40px;
											`}
										>
											<Video
												videoId={asset.videoId}
												buttonOrientation="top-right"
												imageUrl={asset.thumbnail.file.url}
												text={asset.title}
											/>
										</VideoContainer>
									</Media>
								</>
							);
						} else if (asset.thinglinkId) {
							return (
								<ThingLinker
									thinglinkId={asset.thinglinkId}
									originalHeight={asset.originalHeight}
									originalWidth={asset.originalWidth}
								/>
							);
						} else if (asset.infogramId) {
							return (
								<Infogrammer infogramId={asset.infogramId} title={asset.title} />
							);
						} else {
							return <></>;
						}
					} else {
						return <></>;
					}
				} else {
					return <></>;
				}
			},
			[BLOCKS.PARAGRAPH]: (node: any, children: any) => (
				<Typography
					css={css`
						white-space: pre-line;
					`}
					variant="p"
					type={type ?? "primary"}
				>
					{children}
				</Typography>
			),
			[BLOCKS.HEADING_2]: (node: any, children: any) => (
				<Typography
					css={css`
						font-size: 20px;
						line-height: 25px;
						letter-spacing: -1px;
					`}
					variant="h2"
					type={type ?? "primary"}
				>
					{children}
				</Typography>
			),
			[BLOCKS.HEADING_3]: (node: any, children: any) => (
				<Typography
					css={css`
						font-size: 16px;
						line-height: 25px;
						letter-spacing: -1px;
					`}
					variant="h3"
					type={type ?? "primary"}
				>
					{children}
				</Typography>
			),
			[BLOCKS.HEADING_4]: (node: any, children: any) => (
				<Typography
					variant="h4"
					css={css`
						font-size: 16px;
						font-weight: 500;
						font-family: ${theme.typography.fontFamilies.primary};
						line-height: 20px;
						@media (max-width: ${theme.breakpoints.lg}) {
							font-size: 14px;
						}
					`}
					type={type ?? "primary"}
				>
					{children}
				</Typography>
			),
			[BLOCKS.QUOTE]: (node: any, children: any) => {
				let segmentedContent: any[] = [];
				children.forEach((child: any, index: number) => {
					if (child.props.children && child.props.children.length > 0) {
						if (typeof child.props.children[0] === "string") {
							let content = child.props.children[0] ?? "";
							if (content.includes("[") && content.includes("]")) {
								//This is the quote
								content = content.replace("[", "");
								content = content.replace("]", "");
								let quote = (
									<Typography
										key={index}
										variant="h3"
										type="tertiary"
										css={css`
											margin-bottom: 36px;
											letter-spacing: -1px;
											position: relative;
											margin-top: 0px;
											max-width: 560px;
											white-space: normal;
											@media (max-width: ${theme.breakpoints.lg}) {
												white-space: normal;
												font-size: 20px;
												line-height: 24px;
												margin-bottom: 24px;
											}
										`}
									>
										<span
											css={css`
												position: absolute;
												left: -15px;
												display: block;
											`}
										>
											“
										</span>
										{content}
										<span css={css`display;inline-block; margin-left: 7px;`}>
											”
										</span>
									</Typography>
								);
								segmentedContent.push(quote);
							} else {
								segmentedContent.push(child);
							}
						} else {
							segmentedContent.push(child);
						}
					}
				});
				return (
					<Typography
						variant="div"
						size="sm"
						css={css`
							background-color: ${theme.colors.primary.gray};
							p {
								color: ${theme.colors.tertiary.header} !important;
								white-space: pre-line;
								margin: 0;
							}
							p span {
								color: ${theme.colors.tertiary.header} !important;
								white-space: pre-line;
							}
							padding: 46px 65px 50px 65px;
							box-sizing: border-box;
							margin-top: 54px;
							margin-bottom: 56px;
							@media (max-width: ${theme.breakpoints.lg}) {
								padding: 30px 22px 32px 36px;
								margin-top: 34px;
								margin-bottom: 32px;
							}
						`}
					>
						{segmentedContent}
					</Typography>
				);
			},
			[BLOCKS.EMBEDDED_ASSET]: (node: any, children: any) => {
				if (references) {
					let asset: any;
					references.forEach((ref: any, index: any) => {
						if (
							ref &&
							ref.file &&
							ref.file.url &&
							ref.file.url.includes(node.data.target.sys.id)
						) {
							asset = ref;
						}
					});
					if (asset) {
						return (
							<>
								<Media greaterThanOrEqual="lg">
									{asset.description && asset.description.length > 0 ? (
										<CaptionedImage
											title={asset.title}
											caption={asset.description}
											type={type ?? "primary"}
											image={asset.file.url}
										/>
									) : (
										<img
											css={css`
												display: block;
												margin-left: 40px;
												margin-top: 56px;
												margin-bottom: 56px;
												max-width: calc(100% - 80px);
											`}
											src={asset.file.url}
											alt={asset.title}
										/>
									)}
								</Media>
								<Media lessThan="lg">
									{asset.description && asset.description.length > 0 ? (
										<CaptionedImage
											title={asset.title}
											caption={asset.description}
											type={type ?? "primary"}
											image={asset.file.url}
										/>
									) : (
										<ImageWrapper>
											<img
												src={asset.file.url}
												css={css`
													max-width: calc(100% - 40px);
													margin-left: 20px;
													margin-top: 34px;
													margin-bottom: 34px;
													display: block;
												`}
												alt={asset.title}
											/>
										</ImageWrapper>
									)}
								</Media>
							</>
						);
					} else {
						return <></>;
					}
				} else {
					return <></>;
				}
			},
		},
	};
	return documentToReactComponents(JSON.parse(rawData), options);
};
